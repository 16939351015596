<template>
    <v-container>
        <div v-if="subscriptionsEnabled">
            <v-card width="750" class="mx-auto">
                <v-card-title class="display-1 font-weight-light">Membership Required</v-card-title>
                <v-card-text class="title font-weight-light">
                    <p>A subscription is required to use advanced features such as this.</p>
                    <p>Here's what you get:</p>

                    <table class="table text-center elevation-5 pa-5 rfaccent2 white--text" width="100%">
                        <thead>
                        <tr>
                            <th></th>
                            <th class="success white--text">
                                <h4 class="mx-5 mb-0 title font-weight-light">Features Included</h4>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(feature, i) in features" :key="i">
                            <td class="text-left py-2">
                                <span class="d-block">{{ feature.title }}</span>
                                <span class="subtitle-2 font-weight-light">{{ feature.text }}</span>
                            </td>
                            <td>
<!--                                <v-icon color="success" large>mdi-check</v-icon>-->
                                <v-btn fab small color="success" elevation="0"><v-icon>mdi-check</v-icon></v-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="text-center py-5">
                            <span class="title font-weight-light">Just
                                <span class="font-weight-black">$5 <span
                                    class="font-weight-regular">/ month</span></span> <small>or</small> <span
                                    class="font-weight-black">$50 <span
                                    class="font-weight-regular">/ year</span></span> <small class="ml-2"><em>(2 months free!)</em></small>
                            </span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="text-center">
                                <StripeCheckout
                                    ref="checkoutRef"
                                    mode="subscription"
                                    :pk="stripe.publishableKey"
                                    :session-id="stripe.sessionId"
                                    :client-reference-id="user.id.toString()"
                                    :customer-email="user.email"
                                    :success-url="stripe.successURL"
                                    :cancel-url="stripe.cancelURL"
                                    @loading="v => stripe.loading = v"
                                />

                                <v-btn
                                    color="rfaccent"
                                    dark
                                    block
                                    x-large
                                    rounded
                                    class="my-3"
                                    @click="submitMonthly"
                                >
                                    Subscribe for $5/month
                                </v-btn>

                                <v-btn
                                    color="success"
                                    dark
                                    block
                                    x-large
                                    rounded
                                    class="my-3"
                                    @click="submitYearly"
                                >
                                    Subscribe for $50/year
                                </v-btn>

                                <v-btn text small dark class="d-block mx-auto" @click="goBack">Go Back</v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <p class="caption ma-5 pb-0">* A 10% processing fee applies to payments collected by myGMRS.
                        Renewals are charged automatically to your members. Payout of membership fees is by PayPal
                        account or paper check.</p>
                </v-card-text>
            </v-card>
        </div>
        <div v-else>
            <v-row class="justify-center">
                <v-col cols="12" sm="8" md="6" lg="6" xl="4">
                    <v-card color="rforange" light>
<!--                        <v-card-title class="display-1 justify-center"><v-icon size="100">mdi-account-hard-hat</v-icon></v-card-title>-->
                        <v-card-title class="display-1 justify-center"><v-icon size="100">mdi-traffic-cone</v-icon></v-card-title>
<!--                        <v-card-title class="display-1 justify-center"><v-icon size="100">mdi-sign-caution</v-icon></v-card-title>-->
                        <v-card-title class="display-1 justify-center pt-0">Coming Soon</v-card-title>
                        <v-card-text class="subtitle-1 text-center">
                            This feature is still being worked on and will be coming soon. Please check back later!
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
    import {StripeCheckout} from '@vue-stripe/vue-stripe';
    import axios from "axios";
    import config from "@/config";

    export default {
        components: {StripeCheckout},

        props: ['user'],

        data: () => ({
            features: [
                {title: 'No Ads', text: 'Get a 100% ad-free experience'},
                {title: 'Generate PDF Reports', text: 'Reports of repeaters, access requests, and active licenses by state'},
                {title: 'Generate CHIRP CSV Files', text: 'Export repeaters compatible with CHIRP radio software'},
                {title: 'Classifieds Section', text: 'List items for sale or request items you want to buy'},
                {title: 'Create a Radio Club Page', text: 'Get a forum, calendar, and photo gallery hosted by us'},
                {
                    title: 'Collect Membership Fees for your Club',
                    text: 'Accept Credit Card or PayPal Payments* for paid clubs'
                },
                {title: 'Premium Member Forum Badge', text: 'Wear it with pride!'},
                {title: 'Priority Customer Support', text: 'Guaranteed 24-hour Response'},
                {title: 'No Contracts or Commitments', text: 'Cancel Anytime'},
                // {title: '10% Discount on the Store', text: ''},
            ],
            stripe: {
                loading: false,
                publishableKey: config.STRIPE_PUBLISHABLE_KEY,
                sessionId: null,
                successURL: `${config.UI_LOCATION}/profile/subscriptions/success`,
                cancelURL: `${config.UI_LOCATION}/profile/subscriptions`,
                //@todo get the price dynamically from Stripe so we don't need to update the code for pricing changes -- need to get from our API since there's no direct way to query this
            },
            subscriptionsEnabled: config.SUBSCRIPTIONS_ENABLED
        }),

        methods: {
            goBack() {
                this.$router.go(-1);
            },

            submitYearly() {
                this.createCheckoutSession(config.STRIPE_PRICE_YEARLY);
            },

            submitMonthly() {
                this.createCheckoutSession(config.STRIPE_PRICE_MONTHLY);
            },

            createCheckoutSession(priceId) {
                let axiosHeaders = {};
                if (this.user && this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .post(`${config.API_LOCATION}/subscription/session`, {
                        priceId: priceId
                    }, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.stripe.sessionId = response.data.sessionId;

                        this.$refs.checkoutRef.redirectToCheckout();
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        },
    }
</script>
